html, body, #root, .app {
  background: none;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

a:focus {
  outline: none !important;
}

body {
  --font-colour: #FFFFFF;
  --background-colour: #FFFFFF;
  --primary: rgba(4,107,122, 1);
  --secondary: #573280;

  font-family: 'Josefin Sans', sans-serif;
}

.app {
  color: var(--font-colour);
  background-color: var(--background-colour);
  height: 100%;
  position: relative;
  width: 100%;
}

.background-image-container {
  filter: contrast(0.7) brightness(1.2);
  height: 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  transition: top 0.025s linear, left 0.025s linear;
  z-index: 1;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  width: 100%;
  height: 100%;
  background: url("./static/images/ep.jpeg");
  background-position: center;
  background-size: cover;
  transform: scale(1.06);
}

.background-pane {
  background-color: rgba(0, 0, 0, 0.6);
  filter: contrast(0.5) brightness(0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.page {
  align-items: center;
  color: var(--font-colour);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 3;
  padding: calc(17.5vh - 62px) 32px 17.5vh 32px;
}

.page.ultra-small-height {
  padding: 16px;
  padding-top: 32px;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.header.small-screen {

}

.header-text {
  letter-spacing: 2px;
  line-height: 58px;
  font-size: 50px;
  font-weight: 300;
  text-align: center;
  color: white;
}

.header.small-screen .header-text {

}

.header.ultra-small-height .header-text {
  line-height: 46px;
  font-size: 40px;
}

.icon-links-grid {
  display: flex;
  display: grid; 
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  grid-auto-rows: minmax(auto, 1fr);
  gap: 0px 0px;
  width: 100%;
  max-height: 240px;
  max-width: 500px;
  position: relative;
  bottom: 32px;
}

.icon-links-grid.small-screen {

}

.icon-grid-element {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-radius: 24px;
  transition: background-color 0.1s linear, opacity 0.1s linear;
}

.icon-grid-element.small-screen {
  border: none;
  padding: 24px 8px;
}

.icon-grid-element.ultra-slim-width {
  border: none;
  padding: 24px 16px;
}

.icon {
  transition: transform 0.07s linear;
}

.bandcamp-wrapper {
  cursor: pointer;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 0.15);
  padding: 16px 18px;
  max-width: 568px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
}

.bandcamp-wrapper.is-mobile {
  padding: 0;
  background-color: unset;
  max-width: 600px;
}

.bandcamp {
  border: 0px solid rgba(255, 255, 255, 1);
  width: 100%;
  height: 120px;
  align-items: center;
  border-radius: 16px;
  position: relative;
  left: 1px;
  top: 2px;
}

.bandcamp.is-mobile {
  height: 42px;
  border-radius: 0;
  left: 0;
  top: 0;
}

.bandcamp.track-list {
  height: 241px;
}

@media(hover: hover) and (pointer: fine) {
  .icon-grid-element:hover {
    opacity: 0.85;
    background-color:rgba(0, 0, 0, 1);
  }

  .icon-grid-element:hover .icon {
    transform: scale(1.15);
  }

  .bandcamp-wrapper:hover {

  }
  
  .contact:hover {
    background-color: rgba(0, 0, 0, 1);
    transform: scale(1.15);
  }
}

.top-row {
  border-bottom: 0px;
}

.not-last-column {
  border-right: 0px;
}

.floatingchat-container-wrap {

}

.floating-contact {
  background-color: black;
  border-radius: 100px;
  height: 46px;
  display: flex;
  align-items: center;
  font-family: nunito,quicksand,sans-serif;
  font-size: 16px;
  width: max-content;
  color: #fff;
  justify-content: center;
  padding: 0 20px;
  font-weight: 700;
  cursor: pointer;
  display: flex !important;
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 100;
  min-width: 100px;
}